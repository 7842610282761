<template>
  <div id="adminsCard" class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("admin.adminAdministration") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-primary btn-hover"
          @click="$router.push({ name: 'adminAccountUsersCreate' })"
        >
          <i class="fal fa-plus"></i>
          {{ $t("admin.adminCreate") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadAdmins">
        <b-table
          responsive
          :items="admins"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
            <b
              class="btn btn-icon btn-light btn-sm"
              @click="deleteAdmin(data.item.id, data.item.name)"
            >
              <i class="fal fa-trash"></i>
            </b>
          </template>
          <template #cell(name)="data">
            <div class="row flex-wrap py-3">
              <div
                v-if="data.item.profile_picture"
                class="col-auto symbol symbol-50 py-0"
              >
                <div
                  class="symbol-label symbol-label-profile"
                  :style="
                    data.item.profile_picture.link
                      ? 'background-image: url(' +
                        data.item.profile_picture.link +
                        '); background-color: #ffffff;'
                      : ''
                  "
                ></div>
              </div>
              <div class="col-auto flex-grow-1 py-0">
                <span class="d-block">{{ data.item.name }}</span>
                <span class="text-muted">{{ data.item.email }}</span>
              </div>
            </div>
          </template>
          <template #cell(is_admin)="data">
            <span
              v-if="data.item.is_admin"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("admin.admin") }}
            </span>
            <span v-else class="label label-lg label-light-info label-inline">
              {{ $t("admin.user") }}
            </span>
          </template>
          <template #cell(active)="data">
            <span
              v-if="data.item.active"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("general.active") }}
            </span>
            <span v-else class="label label-lg label-light-danger label-inline">
              {{ $t("general.inactive") }}
            </span>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { formatDate } from "@/components/Tools/modifiers";
import Admins from "@/components/Admins/Account/Admin/admins";
import Media from "@/components/Admins/Media/media";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  name: "AdminsAccountAdminTable",
  components: { TableWrapper },
  data() {
    return {
      admins: [],
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "name",
          label: this.$t("admin.user"),
          sortable: true
        },
        {
          key: "is_admin",
          label: this.$t("admin.role"),
          sortable: true
        },
        {
          key: "active",
          label: this.$t("table.status"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      // Meta info
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.loadAdmins();
    this.$root.$on("adminsTableRefresh", () => {
      this.loadAdmins();
    });
  },
  methods: {
    loadAdmins() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      Admins.getAll(params, cancelToken)
        .then(response => {
          this.admins = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    formatDateAssigned(value) {
      return formatDate(value);
    },
    showDetails(id) {
      this.$router.push({
        name: "adminAccountUsersDetails",
        params: { id: id }
      });
    },
    deleteAdmin(id, name) {
      let self = this;
      Swal.fire({
        title: this.$t("admin.adminDeleteTitle"),
        text: this.$t("admin.adminDeleteMessage", { user: name }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          let adminData = [];
          Admins.get(id).then(response => {
            adminData = response.data.data;
          });

          Admins.delete(id)
            .then(() => {
              if (adminData.profile_picture_id) {
                Media.delete(adminData.profile_picture_id);
              }
              this.$toast.fire({
                icon: "success",
                title: this.$t("users.userDeleted")
              });
              if (self.admins.length === 1 && self.page !== 1) {
                self.handlePageChange(self.page - 1);
              } else {
                self.loadAdmins();
              }
            })
            .catch(error => {
              Swal.fire("Error", error.response.data.message, "error");
            });
        }
      });
    }
  }
};
</script>
